import React from 'react';
import { graphql } from 'gatsby';

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Post from "../../components/post";
import "./tag-page.scss"

const TagPage = ({ data, pageContext }) => {
    const { tag } = pageContext;
    const { totalCount } = data.allMarkdownRemark;
    const pageHeader = `${totalCount} post${
        (totalCount === 1) ? '' : 's'
    } tagged "${tag}"`;

    return (
        <Layout>
            <SEO/>
            <h2>{pageHeader}</h2>
            {data.allMarkdownRemark.edges.map(({ node }) => (
                <Post
                    preSlug={`/${node.frontmatter.postType}/`}
                    key={node.id}
                    title={node.frontmatter.title}
                    author={node.frontmatter.author}
                    slug={node.fields.slug}
                    date={node.frontmatter.date}
                    body={node.excerpt}
                    tags={node.frontmatter.tags}
                />
            ))}
        </Layout>
    )
}

export const tagQuery = graphql`
    query($tag: String!) {
        allMarkdownRemark(
            sort: { fields: [frontmatter___date], order: DESC}
            filter: { frontmatter: { tags: { in: [$tag] } } }
        ) {
            totalCount
            edges {
                node {
                    id
                    frontmatter {
                        title
                        date(formatString: "MMM Do YYYY")
                        author
                        postType
                        tags
                    }
                    fields {
                        slug
                    }
                    excerpt(pruneLength: 250)
                }
            }
        }
    }
`

export default TagPage